import { Component } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  form: any;
  constructor(private formBuilder: FormBuilder) {
    this.form = formBuilder.group({
      name: '',
      email: '',
      messagem: ''});

   }


  log(x: any) {
    console.log(x);
  }

}
