import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], attribute: string, filter: any): any {
    if(!items || !attribute || !filter) return items;

    return items.filter(item => {
      const value = new String(item[attribute]).toLowerCase(); 
      return value  === filter
    });
  }

}
