<app-banner></app-banner>
<div class="section cc-store-home-wrap">
    <div class="container">
        <div class="home-content-wrap">
            <div class="w-layout-grid about-grid">
                <div id="w-node-_86e64837-0616-515b-4568-76c147234d34-3a219ebc">
                    <div class="home-section-wrap">
                        <div class="label cc-light">QUEM SOMOS?</div>
                        <h2 class="section-heading">DIKANZA 3D</h2>
                        <p class="paragraph-light">Fundada em 2023 por empreendedores angolanos apaixonados por design interior, a Dikanza 3D atua no mercado de Decoração e Design de Interiores especializada em Painéis de Gesso 3D....</p>
                    </div>
                    <a routerLink="about" class="button w-inline-block">
                        <div>Ler mais</div>
                    </a>
                </div><img src="https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64856ecdcdba2a48db879d39_chair_2-p-800.png" sizes="100vw" srcset="https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64856ecdcdba2a48db879d39_chair_2-p-500.png 500w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64856ecdcdba2a48db879d39_chair_2-p-800.png 800w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64856ecdcdba2a48db879d39_chair_2-p-1080.png 1080w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64856ecdcdba2a48db879d39_chair_2.png 1104w" id="w-node-_86e64837-0616-515b-4568-76c147234d3f-3a219ebc" alt="Painel 3D em Cinza Industrial">
            </div>
            <div class="w-layout-grid about-grid cc-about-2">
                <div id="w-node-_86e64837-0616-515b-4568-76c147234d41-3a219ebc">
                    <div class="home-section-wrap">
                        <div class="label cc-light">time</div>
                        <h2 class="section-heading">O QUE FAZEMOS?</h2>
                        <p class="paragraph-light">Somos especialistas em Decoração de Ambientes com Materiais 3D, utilizando Gesso. Nosso serviço inclui produção personalizada de placas de Gesso 3D e instalação em salas ou ambientes abertos.</p>
                    </div><a routerLink="projects" class="button w-inline-block">
                        <div>Saiba mais</div>
                    </a>
                </div><img src="https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64856f3655c5edc7b11766de_Captura%20de%20Tela%202023-06-11%20a%CC%80s%2003.52.22-p-500.png" id="w-node-_86e64837-0616-515b-4568-76c147234d4c-3a219ebc" sizes="100vw" srcset="https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64856f3655c5edc7b11766de_Captura%20de%20Tela%202023-06-11%20a%CC%80s%2003.52.22-p-500.png 500w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64856f3655c5edc7b11766de_Captura%20de%20Tela%202023-06-11%20a%CC%80s%2003.52.22-p-800.png 800w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64856f3655c5edc7b11766de_Captura%20de%20Tela%202023-06-11%20a%CC%80s%2003.52.22-p-1080.png 1080w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64856f3655c5edc7b11766de_Captura%20de%20Tela%202023-06-11%20a%CC%80s%2003.52.22.png 1140w" alt="Parede com modelos de Painel 3D em Cinza Industrial">
            </div>
        </div>
    </div>
</div>
<app-contract></app-contract>
<div class="section">
    <div class="container">
        <div class="motto-wrap">
            <div class="label cc-light">Nós acreditamos</div>
            <div class="heading-jumbo-small">Para crescer, é vital prestar com excelência nossos serviços e manter bom relacionamento com nossos clientes.<br></div>
        </div>
        <div class="blog-heading">
            <div class="label cc-light">PARA VOCÊ</div>
            <h2 class="work-heading">RELAÇÕES PÚBLICAS</h2>
        </div>
        <div class="collection-list-wrapper w-dyn-list">
            <div role="list" class="collection-wrap w-dyn-items">
                <div role="listitem" class="blog-preview-wrap w-dyn-item"><a routerLink="/post/como-limpar-gesso-3d" class="business-article-heading">Como Limpar de Gesso 3D</a>
                    <div class="label cc-blog-date">Feb 21, 2019</div>
                    <p class="paragraph-light">Neste guia completo, aprenderemos as melhores práticas para limpar e manter adequadamente as superfícies de gesso 3D, garantindo que elas continuem a encantar por muitos anos.</p>
                </div>
                <div role="listitem" class="blog-preview-wrap w-dyn-item"><a routerLink="/post/15-best-blogs-to-follow-about-web-design" class="business-article-heading">5 Casos de Uso de Gesso 3D</a>
                    <div class="label cc-blog-date">Feb 21, 2019</div>
                    <p class="paragraph-light">Neste guia completo, aprenderemos as melhores práticas para limpar e manter adequadamente as superfícies de gesso 3D, garantindo que elas continuem a encantar por muitos anos.</p>
                </div>
                <div role="listitem" class="blog-preview-wrap w-dyn-item"><a routerLink="/post/7-of-the-best-examples-of-beautiful-blog-design" class="business-article-heading">7 Erros relativos a Paredes Permeáveis</a>
                    <div class="label cc-blog-date">Feb 21, 2019</div>
                    <p class="paragraph-light">Neste guia completo, aprenderemos as melhores práticas para limpar e manter adequadamente as superfícies de gesso 3D, garantindo que elas continuem a encantar por muitos anos.</p>
                </div>
            </div>
        </div>
    </div>
</div>