<div class="section wf-section">
    <div class="wrapper">
        <div class="shop-header">
            <h3 class="no-margin w-hidden-small w-hidden-tiny">Modelos</h3>
            <div class="shop-categories-wrapper">
                <div class="w-dyn-list">
                    <div role="list" class="shop-categories w-dyn-items">
                        <div role="listitem" class="w-dyn-item"  *ngFor="let f of this.state.filter"><a routerLink="https://toystore-template.webflow.io/category/wooden-toys" class="shop-category-link" [class.w--current]="f.name==this.filter" (click)="selectFilter(f.name)">{{f.description}}</a></div>
                    </div>
                </div>
            </div>
            <div class="shop-header-line">
                <div class="shop-header-color green w-hidden-small w-hidden-tiny"></div>
            </div>
        </div>
        <div class="full-width w-dyn-list">
            <div role="list" class="products w-dyn-items">
                <div role="listitem" *ngFor="let pd of catalog | filter:'model':filter" class="product-card-wrapper w-dyn-item"><a routerLink="teddy-bear" class="product-card w-inline-block">
                        <div class="product-card-image-wrapper">
                            <img data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_main_image_4dr%22%2C%22to%22%3A%22src%22%7D%5D" [src]="pd.image" alt=""></div>
                        <h6 class="product-card-heading">{{pd.name}}</h6>
                        <div class="product-card-info">Comprimento: {{pd.dimensions.width}} </div>
                        <div class="product-card-info">Largura: {{pd.dimensions.height}} </div>
                        <div data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_price_%22%2C%22to%22%3A%22innerHTML%22%7D%5D" class="product-card-price">{{ pd.price | kwanza }} (m²)</div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>