<div class="section">
    <div class="intro-header cc-subpage"><img src="./contact_files/64857baadcb963c3f71526c2_SVGwhite.png" loading="lazy" width="554" sizes="(max-width: 767px) 94vw, 554px" alt="Logotipo Dikanza 3D" srcset="https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64857baadcb963c3f71526c2_SVGwhite-p-500.png 500w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64857baadcb963c3f71526c2_SVGwhite-p-800.png 800w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64857baadcb963c3f71526c2_SVGwhite-p-1080.png 1080w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64857baadcb963c3f71526c2_SVGwhite.png 1788w"></div>
</div>
<div class="section">
    <div class="container">
        <div class="w-layout-grid contact-form-grid">
            <div id="w-node-_3f61509a-fac7-b0d5-3763-509be705858e-3a219ec6" class="contact-form-wrap">
                <div class="contact-form-heading-wrap">
                    <h2 class="contact-heading">Entre em contato</h2>
                    <div class="paragraph-light">Preencha o formulário e entre em contato conosco.</div>
                </div>
                <div class="contact-form w-form">
                    <form (ngSubmit)="log($event)" formGroup="form"> data-name="Get In Touch Form" name="wf-form-Get-In-Touch-Form" id="email-form" method="get" class="get-in-touch-form" data-wf-page-id="64855f1e841d2b143a219ec6" data-wf-element-id="3f61509a-fac7-b0d5-3763-509be7058595" aria-label="Get In Touch Form">
                        <label for="Name">Nome</label>
                        <input type="text" class="text-field cc-contact-field w-input" maxlength="256" name="name" data-name="Name" placeholder="Digite seu nome" id="Name">
                        <label for="Email">Email Address</label>
                        <input type="email" class="text-field cc-contact-field w-input" maxlength="256" name="Email" data-name="Email" placeholder="Digite seu email" id="Email" required="">
                        <label for="Message">Message</label>
                        <textarea id="Message" name="Message" maxlength="5000" data-name="Message" placeholder="Olá, Dikanza 3D. Entro em contato para contratar vocês serviços..." class="text-field cc-textarea cc-contact-field w-input"></textarea>
                        <input type="submit" value="Enviar" data-wait="Por favor, aguarde..." class="button w-button">
                    </form>
                    <div class="status-message cc-success-message w-form-done" tabindex="-1" role="region" aria-label="Get In Touch Form success">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div class="status-message cc-error-message w-form-fail" tabindex="-1" role="region" aria-label="Get In Touch Form failure">
                        <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div>
            </div>
            <div id="w-node-_3f61509a-fac7-b0d5-3763-509be70585a6-3a219ec6">
                <div class="details-wrap">
                    <div class="label">Nosso escritório</div>
                    <div class="paragraph-light">No momento temos as operações no escritório interditadas.</div>
                </div>
                <div class="details-wrap">
                    <div class="label">Endereço</div>
                    <div class="paragraph-light">Rua da Samba, 614, Bairro Samba<br>Luanda, Angola<br>LA, 00000</div>
                </div>
                <div class="details-wrap">
                    <div class="label">Horários</div>
                    <div class="paragraph-light">9H - 18H, Seg à Sex</div>
                </div>
                <div class="details-wrap">
                    <div class="label">contato</div><a href="mailto:contact@business.com?subject=You%27ve%20got%20mail!" class="contact-email-link">contato@dikanza3d.com</a>
                    <div class="paragraph-light">+244 952 130 560</div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section">
    <div class="container">
        <div class="section-heading-wrap">
            <div class="label cc-light">SOMOS OS MELHORES</div>
        </div>
        <div class="w-layout-grid our-contacts">
            <div id="w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb08-3a219ec6">
                <div class="contact-team-pic"></div>
                <div class="contact-team-name-wrap">
                    <div class="paragraph-bigger">Jerameel J. Gonga</div>
                    <div class="paragraph-light">CEO</div>
                </div>
                <div class="divider"></div>
                <div class="contact-team-details-wrap"><a href="mailto:ceo@business.com?subject=You%27ve%20got%20mail!" class="contact-email-link">jegonga@dikanza3d.com</a>
                    <div class="paragraph-light">+55 (11) 9894 83122</div>
                </div>
            </div>
            <div id="w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb15-3a219ec6">
                <div class="contact-team-pic"></div>
                <div class="contact-team-name-wrap">
                    <div class="paragraph-bigger">Jelson M. Junqueira</div>
                    <div class="paragraph-light">COO</div>
                </div>
                <div class="divider"></div>
                <div class="contact-team-details-wrap"><a href="mailto:cto@business.com?subject=You%27ve%20got%20mail!" class="contact-email-link">jelsonjunqueira@dikanza3d.com</a>
                    <div class="paragraph-light">+244 928 112 089</div>
                </div>
            </div>
            <div id="w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb22-3a219ec6">
                <div class="contact-team-pic"></div>
                <div class="contact-team-name-wrap">
                    <div class="paragraph-bigger">Departamento de Marketing</div>
                    <div class="paragraph-light">Relações públicas</div>
                </div>
                <div class="divider"></div>
                <div class="contact-team-details-wrap"><a href="mailto:design@business.com?subject=You%27ve%20got%20mail!" class="contact-email-link">contato@dikanza3d.com</a>
                    <div class="paragraph-light">+244 952 130 560</div>
                </div>
            </div>
        </div>
    </div>
</div>