<div class="section cc-cta">
    <div class="container">
        <div class="cta-wrap">
            <div>
                <div class="cta-text">
                    <div class="heading-jumbo-small">CONTRATE NOSSOS SERVIÇOS<br></div>
                    <div class="paragraph-bigger cc-bigger-light">Não por acaso, hoje nossos caminhos se cruzaram e disso começará uma relação de mútua confiaça.<br></div>
                </div>
                <a href="http://wa.me/244952130560?text=Ol%C3%A1%20Dikanza%203D.%20Estou%20inderessado%20nos%20vossos%20servi%C3%A7os%20e%20preciso%20de%20um%20or%C3%A7amento." routerLinkActive="router-link-active" class="button cc-jumbo-button w-inline-block">
                    <div>Perdir Orçamento</div>
                </a>
            </div>
        </div>
    </div>
</div>