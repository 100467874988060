<div class="section cc-home-wrap">
    <div class="intro-header cc-subpage"><img src="./about_files/64857baadcb963c3f71526c2_SVGwhite.png" loading="lazy" width="728" sizes="(max-width: 479px) 94vw, (max-width: 767px) 95vw, (max-width: 991px) 92vw, 728px" alt="Logotipo da Dikanza 3D" srcset="https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64857baadcb963c3f71526c2_SVGwhite-p-500.png 500w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64857baadcb963c3f71526c2_SVGwhite-p-800.png 800w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64857baadcb963c3f71526c2_SVGwhite-p-1080.png 1080w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64857baadcb963c3f71526c2_SVGwhite.png 1788w" class="image-3"></div>
    <div class="container">
        <div class="motto-wrap">
            <div class="heading-jumbo-small">Somos uma empresa de pensadores criativos que construíram um negócio para transformar ambientes.</div>
        </div>
        <div class="divider"></div>
        <div class="about-story-wrap">
            <p class="paragraph-light">A Dikanza 3D é uma empresa africana com produção na Angola, especializada no design e fabricação de materiais decorativos para acabamentos, como painéis de gesso em 3D. Oferecemos uma coleção de painéis decorativos em 3D para espaços comerciais, residenciais e públicos: shoppings, lojas, hotéis, escritórios, casas, cinemas, restaurantes, salas de espera de aeroportos e muito mais. <br>‍<br>Apresentamos uma ampla gama de produtos com qualidade excepcional e design original, que tornam qualquer ambiente acolhedor, moderno e luxuoso. Isso não apenas impressionará, mas também aumentará o valor da propriedade do proprietário. Hoje, a Dikanza 3D é uma equipe de profissionais que trabalha para você. O fluxo de trabalho de cada departamento é aperfeiçoado nos mínimos detalhes e tem como objetivo estabelecer relacionamentos de confiança com nossos clientes regulares. <br><br>Não temos atrasos na execução de pedidos nem atrasos no envio de produtos para
                os clientes.Cada painel 3D é cuidadosamente verificado pelos supervisores e embalado em uma embalagem especial de marca que proporciona proteção confiável contra impactos durante o transporte.Na fabricação de painéis decorativos em 3D, utilizamos apenas matrizes de silicone de alta qualidade, projetadas para produção em massa. A secagem adequada dos painéis de gesso em 3D é garantida ao colocá-los em prateleiras profissionais, onde cada painel de gesso é colocado horizontalmente em uma célula separada.<br><br>O objetivo da empresa é convencer de que a qualidade da Dikanza 3D merece seu respeito e que estamos prontos para uma parceria.<br><br>Nossa missão: levar a beleza das paredes para os interiores ao redor do mundo.</p>
        </div>
        <div class="divider"></div>
    </div>
</div>
<div class="section">
    <div class="container">
        <div class="section-heading-wrap">
            <div class="label cc-light">Somos os melhores nos</div>
            <h2>Nossos Serviços</h2>
        </div>
        <div class="w-layout-grid our-services-grid">
            <div id="w-node-ca1212d8-4247-8b87-bc90-543393b118e6-3a219ec4"><img src="./about_files/64855f1e841d2b143a219eeb_Icon 1.svg" width="50" alt="" class="service-icon">
                <div class="paragraph-bigger">Instalação</div>
                <div class="paragraph-light">Instalação precisa e cuidadosa dos painéis de gesso 3D de acordo com os mais altos padrões de qualidade, assegurando um acabamento perfeito e duradouro.</div>
            </div>
            <div id="w-node-ca1212d8-4247-8b87-bc90-543393b118ec-3a219ec4"><img src="./about_files/64855f1e841d2b143a219ef1_Icon 1.svg" width="50" alt="" class="service-icon">
                <div class="paragraph-bigger">Manutenção</div>
                <div class="paragraph-light"> Garantir que os painéis de gesso 3D permaneçam em excelente estado ao longo do tempo. Limpeza regular, reparos necessários e a substituição de peças.</div>
            </div>
            <div id="w-node-ca1212d8-4247-8b87-bc90-543393b118f2-3a219ec4"><img src="./about_files/64855f1e841d2b143a219ee8_Icon 3.svg" width="50" alt="" class="service-icon">
                <div class="paragraph-bigger">Projeto</div>
                <div class="paragraph-light">Nossa equipe de designers especializados, desenvolve soluções criativas e únicas para atender às necessidades estéticas e funcionais de cada espaço.</div>
            </div>
            <div id="w-node-ca1212d8-4247-8b87-bc90-543393b118f8-3a219ec4"><img src="./about_files/64855f1e841d2b143a219eeb_Icon 1.svg" width="50" alt="" class="service-icon">
                <div class="paragraph-bigger">Eventos</div>
                <div class="paragraph-light">Seja para exposições, feiras, lançamentos de produtos ou qualquer outro tipo de evento, instalações temporárias ou permanentes para tornar o ambiente mais atrativo e impressionante.</div>
            </div>
            <div id="w-node-ca1212d8-4247-8b87-bc90-543393b118fe-3a219ec4"><img src="./about_files/64855f1e841d2b143a219ee8_Icon 3.svg" width="50" alt="" class="service-icon">
                <div class="paragraph-bigger">Fabrico<br></div>
                <div class="paragraph-light">Fabricação própria, produzimos painéis de gesso 3D com precisão e qualidade utilizando tecnologia avançada e materiais de alta qualidade com resultado final excepcional.</div>
            </div>
            <div id="w-node-ca1212d8-4247-8b87-bc90-543393b11905-3a219ec4"><img src="./about_files/64855f1e841d2b143a219eec_Icon 2.svg" width="50" alt="" class="service-icon">
                <div class="paragraph-bigger">Consultoria <br></div>
                <div class="paragraph-light">Oferecemos serviços de consultoria especializada em gesso 3D, auxiliando os clientes na escolha dos melhores produtos, design e soluções para seus projetos.</div>
            </div>
        </div>
    </div>
</div>
<app-contract></app-contract>