
<div class="section cc-home-wrap">
    <div class="intro-header cc-subpage projects">
        <div class="intro-content">
            <div class="heading-jumbo work">NOSSOS TRABALHOS<br></div>
        </div>
    </div>
</div>
<div class="section">
    <div class="container">
        <div class="section-heading-wrap">
            <div class="label cc-light">NOSSOS TRABALHOS</div>
            <h2>PROJETOS REALIZADOS</h2>
        </div>
        <div class="w-layout-grid projects-grid">
            <div id="w-node-_2878ffd7-26fe-9471-3c4e-7763e2d38cdc-3a219ecb"><a href="https://dikanza3d.webflow.io/work/project-1" class="project-cover-link w-inline-block"><img src="./works_files/64860d70dcb963c3f7826ecf_beautywalls-3d-plaster-panels-Stripes-3.jpeg" sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, 94vw"
                        srcset="https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64860d70dcb963c3f7826ecf_beautywalls-3d-plaster-panels-Stripes-3-p-500.jpeg 500w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64860d70dcb963c3f7826ecf_beautywalls-3d-plaster-panels-Stripes-3-p-800.jpeg 800w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64860d70dcb963c3f7826ecf_beautywalls-3d-plaster-panels-Stripes-3-p-1080.jpeg 1080w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64860d70dcb963c3f7826ecf_beautywalls-3d-plaster-panels-Stripes-3-p-1600.jpeg 1600w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64860d70dcb963c3f7826ecf_beautywalls-3d-plaster-panels-Stripes-3.jpeg 1894w" alt="Parede em Gesso Stripes" class="project-cover-pic"></a>
                <div class="project-name-wrap"><a href="https://dikanza3d.webflow.io/work/project-1" class="project-name-link">Modelo Stripes</a>
                    <div class="paragraph-light">Art direction</div>
                </div>
            </div>
            <div id="w-node-_2878ffd7-26fe-9471-3c4e-7763e2d38ce3-3a219ecb"><a href="https://dikanza3d.webflow.io/work/project-2" class="project-cover-link w-inline-block"><img src="./works_files/64860e0cbfa0a739d8f9456d_beautywalls-3d-plaster-panels-Moon-1.jpeg" sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, 94vw"
                        srcset="https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64860e0cbfa0a739d8f9456d_beautywalls-3d-plaster-panels-Moon-1-p-500.jpeg 500w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64860e0cbfa0a739d8f9456d_beautywalls-3d-plaster-panels-Moon-1-p-800.jpeg 800w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64860e0cbfa0a739d8f9456d_beautywalls-3d-plaster-panels-Moon-1-p-1080.jpeg 1080w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64860e0cbfa0a739d8f9456d_beautywalls-3d-plaster-panels-Moon-1-p-1600.jpeg 1600w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64860e0cbfa0a739d8f9456d_beautywalls-3d-plaster-panels-Moon-1.jpeg 1894w" alt="Parede em Gesso Lua" class="project-cover-pic"></a>
                <div class="project-name-wrap"><a href="https://dikanza3d.webflow.io/work/project-2" class="project-name-link">Modelo Lua</a>
                    <div class="paragraph-light">Parede em Gesso 3D</div>
                </div>
            </div>
            <div id="w-node-_2878ffd7-26fe-9471-3c4e-7763e2d38cea-3a219ecb"><a href="https://dikanza3d.webflow.io/work/project-3" class="project-cover-link w-inline-block"><img src="./works_files/6489194698d83b71ff223796_Captura de Tela 2023-06-13 às 22.34.47.png" sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, 94vw" srcset="https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/6489194698d83b71ff223796_Captura%20de%20Tela%202023-06-13%20a%CC%80s%2022.34.47-p-500.png 500w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/6489194698d83b71ff223796_Captura%20de%20Tela%202023-06-13%20a%CC%80s%2022.34.47-p-800.png 800w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/6489194698d83b71ff223796_Captura%20de%20Tela%202023-06-13%20a%CC%80s%2022.34.47-p-1080.png 1080w, https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/6489194698d83b71ff223796_Captura%20de%20Tela%202023-06-13%20a%CC%80s%2022.34.47.png 1600w" alt="Parede em Gesso 3D Dunas"
                        class="project-cover-pic"></a>
                <div class="project-name-wrap"><a href="https://dikanza3d.webflow.io/work/project-3" class="project-name-link">Projeto Duna</a>
                    <div class="paragraph-light">Paredes em Gesso 3D</div>
                </div>
            </div>
        </div>
    </div>
</div>