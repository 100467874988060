import { NgModule } from '@angular/core';
import { RouterModule, RouterOutlet, Routes } from '@angular/router';
import { HomeComponent } from './features/landing-page/home/home.component';
import { AboutComponent } from './features/landing-page/about/about.component';
import { WorksComponent } from './features/landing-page/works/works.component';
import { CatalogComponent } from './features/landing-page/catalog/catalog.component';
import { BlogComponent } from './features/landing-page/blog/blog.component';
import { ContactComponent } from './features/landing-page/contact/contact.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, pathMatch: 'full', data: {animation: 'home'}},
  { path: 'about', component: AboutComponent, pathMatch: 'full', data: {animation: 'about'}},
  { path: 'works', component: WorksComponent, pathMatch: 'full', data: {animation: 'works'}},
  { path: 'blog', component: BlogComponent, pathMatch: 'full', data: {animation: 'blog'}},
  { path: 'catalog', component: CatalogComponent, pathMatch: 'full', data: {animation: 'catalog'}},
  { path: 'contact', component: ContactComponent, pathMatch: 'full', data: {animation: 'contact'}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
