import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kwanza'
})
export class KwanzaPipe implements PipeTransform {

  constructor(private currency : CurrencyPipe) { }

  transform(valor: string|number): String {
    valor = ""+ valor;
    valor = this.currency.transform(valor, 'AOA', 'symbol', '1.2-2') + ""; 
    valor = valor.replace('AOA', '').replace(',', '.') + " kz";


    return valor;
  }

}
