<div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navigation w-nav">
    <div class="navigation-wrap"><a routerLink="/" aria-current="page" class="logo-link w-nav-brand w--current" aria-label="home">
            <img src="https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64856630adbde29bba4aee6b_SVG.png" loading="lazy" width="140" alt="Logotipo da Dikanza 3D"></a>
        <div class="menu">
            <nav role="navigation" class="navigation-items w-nav-menu" style="transform: translateY(0px) translateX(0px);">
                <a routerLink="/catalog" class="navigation-item w-nav-link w-close" aria-label="menu" role="button" tabindex="0" aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="true">CATÁLOGO</a>
                <a routerLink="/about" class="navigation-item w-nav-link w-close">SOBRE</a>
                <a routerLink="/works" class="navigation-item w-nav-link w-close">TRABALHOS</a>
                <a routerLink="/blog" class="navigation-item w-nav-link w-close">PORTFOLIO</a>
                <a routerLink="/contact" class="navigation-item w-nav-link w-close w-close">CONTATO</a>
            </nav>
            <div class="menu-button w-nav-button" style="-webkit-user-select: text;" aria-label="menu" role="button" tabindex="0" aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="true"><img src="https://uploads-ssl.webflow.com/64855f1e841d2b143a219e92/64855f1e841d2b143a219ede_menu-icon.png" width="22" alt="" class="menu-icon"></div>
        </div>
        <a href="http://wa.me/244952130560?text=Ol%C3%A1%20Dikanza%203D.%20Estou%20inderessado%20nos%20vossos%20servi%C3%A7os%20e%20preciso%20de%20um%20or%C3%A7amento." target="_blank" class="button cc-contact-us w-inline-block">
            <div>ORÇAMENTO</div>
        </a>
    </div>
    <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style="display: none;"></div>
</div>