import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { WorksComponent } from './works/works.component';
import { CatalogComponent } from './catalog/catalog.component';
import { PipesModule } from "../../core/pipes/pipes.module";
import { FilterPipe } from 'src/app/core/pipes/filter.pipe';



@NgModule({
    declarations: [
        WorksComponent,
        CatalogComponent
    ],
    exports: [],
    imports: [
        CommonModule,
        PipesModule
    ],
    providers: [CurrencyPipe, FilterPipe],
})
export class LandingPageModule { }
