import { Component } from '@angular/core';
import { Product } from 'src/app/core/domain/Product';
import catalog  from 'src/app/core/config/catalog.json';
import { anims } from 'src/app/core/animation/animation';
@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
  animations: [
    anims
  ]
})
export class CatalogComponent {

  constructor() { }

  
  state = {
    module: 'catalog',
    action: '',
    data: undefined,
    filter: [{name: "classic", description: "Clássico"}, {name: "exotic", description: "Exótico"}, {name: "modern", description: "Moderno"}]
  };
  
  filter: string = "classic";

  catalog: Product[] = catalog.products;

  ngOnInit() {
  }

  selectFilter(filter: string) {
    this.filter = filter;
  }

}
